import React, { Component } from "react";

class About extends Component {
  render() {
    return (
      <div className="banner color about">
        <h2>Sobre mí</h2>
        <p className="textalign left">
          Soy programador junior de ReactJS, Node.js, Java, MySQL.
          <br />
          Actualmente estoy cursando 'Desarrollo de aplicaciones web' y ampliando mi formación en
          Java, JavaScript, HTML, CSS y MySQL.
        </p>
      </div>
    );
  }
}

export default About;
