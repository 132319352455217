import React, { Component } from "react";
import SkillsData from "../../data/SkillsData";

class Skills extends Component {
  render() {
    const skills = SkillsData.map((item) => {
      return (
        <div key={item.name} className="skill Item">
          <img alt={item.name} src={item.image} />
          <h3>{item.name}</h3>
          <p>{item.description}</p>
        </div>
      );
    });

    return <div className="skillsSection">{skills}</div>;
  }
}

export default Skills;
