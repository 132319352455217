import GithubIcon from "../assets/social-icons/github.png";
import InstagramIcon from "../assets/social-icons/instagram_square_black.png";
import LinkedInIcon from "../assets/social-icons/linkedin_basic_big.png";

const SocialData = [
  {
    key: "1",
    name: "Github",
    title: "@JuanGutierrezA on GitHub",
    href: "https://github.com/juangutierreza",
    icon: GithubIcon,
  },
  {
    key: "2",
    name: "Instagram",
    title: "@JuanGutierrezA on Instagram",
    href: "https://instagram.com/juangutierreza",
    icon: InstagramIcon,
  },
  {
    key: "3",
    name: "LinkedIn",
    title: "Juan Gutierrez on LinkedIn",
    href: "https://www.linkedin.com/in/juan-gutierreza",
    icon: LinkedInIcon,
  },
];

export default SocialData;
