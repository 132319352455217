import React, { Component } from "react";
import "./css/App.css";
import "./css/AboutMe.css";
import "./css/SingTheWord.css";
import styled from "styled-components";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Inicio from "./routes/Inicio.js";
import Testing from "./routes/Testing";
// import Ciclismo from "./routes/-notUsing/Ciclismo";
// import Instagram from "./routes/-notUsing/Instagram";
// import Privacy from "./routes/Privacy";
import FotoAcirfaRural from "./routes/FotoAcirfaRural";
import SingTheWord from "./routes/SingTheWord";
import QRgen from "./routes/QRgen";
import Gas from "./routes/Gas";

const DisplaySize = 870;
// const ColorWeb = "var(--color-green)";
const ColorWeb = "var(--secondary-color)";

// Position Absolute
const CajaConTodo = styled.div`
  background-color: ${ColorWeb};
  display: flex;
  flex-flow: column;
  position: absolute;
  height: 100%;
  width: 100%;
`;

// Fondo y bloquea la pantalla, luego haces un desplegable dentro si lo necesitas
const Main = styled.div`
  display: flex;
  color: white;
  flex-flow: column;
  flex-basis: 100%;
  flex-grow: 1;
  align-content: center;

  @media (min-width: ${DisplaySize}px) {
    overflow: hidden;
  }
`;

class App extends Component {
  state = {
    route: `DEV${window.location.pathname}`,
  };

  componentDidMount() {
    let myHeaders = new Headers({
      route: this.state.route,
    });
    let myInit = {
      method: "GET",
      headers: myHeaders,
      mode: "cors",
      cache: "default",
    };
    fetch(`${process.env.REACT_APP_API_URL}/visit`, myInit);

    return null;
  }

  render() {
    return (
      <CajaConTodo>
        <Router>
          <Main>
            <Switch>
              <Route path="/fotoacirfarural" component={FotoAcirfaRural}></Route>

              {/* <Route path="/Instagram" component={Instagram}></Route> */}
              {/* <Route path="/ciclismo" component={Ciclismo}></Route> */}
              {/* <Route path="/privacy" component={Privacy}></Route> */}
              <Route path="/testing" component={Testing}></Route>
              <Route path="/gas" component={Gas}></Route>

              <Route path="/singtheword" component={SingTheWord}></Route>
              <Route path="/qrgen" component={QRgen}></Route>

              <Route path="/" component={Inicio}></Route>
            </Switch>
          </Main>
        </Router>
      </CajaConTodo>
    );
  }
}

export default App;
