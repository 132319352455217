import GitIMG from "../assets/skills/git.png";
import JavaIMG from "../assets/skills/java.png";
import ReactIMG from "../assets/skills/reactjs.png";
import NodeIMG from "../assets/skills/nodejs.png";
import BootstrapIMG from "../assets/skills/bootstrap.png";
import MongoIMG from "../assets/skills/mongodb.png";
import MysqlIMG from "../assets/skills/mysql.png";
import SemanticIMG from "../assets/skills/semantic.png";

// NETLIFY, DOCKER (?)

const SkillsData = [
  {
    name: "Git",
    description:
      "Utilizo Git en todos mis proyectos. Lo utilizo para llevar un control de versiones y colaborar con gente.",
    image: GitIMG,
  },
  {
    name: "Java",
    description:
      "Estoy cursando Java actualmente. Me estoy familiarizando sin problemas con su estructura y su funcionamiento.",
    image: JavaIMG,
  },
  {
    name: "ReactJS",
    description:
      "ReactJS es la primera tecnología que aprendí y con la que más familiarizado estoy. Esta web está basada en React.",
    image: ReactIMG,
  },
  {
    name: "Node.js",
    description:
      "Node.js es mi elección para entorno servidor. Lo he utilizado siempre para programar APIs.",
    image: NodeIMG,
  },
  {
    name: "Semantic-React",
    description: "Utilizo Semantic desde que empecé a programar en React para hacer el diseño.",
    image: SemanticIMG,
  },
  {
    name: "Bootstrap 4",
    description:
      "Estoy empezando a utilizar Bootstrap por las facilidades que aporta y su rapidez creando el diseño de una web.",
    image: BootstrapIMG,
  },
  {
    name: "MongoDB",
    description:
      "Es la base de datos no relacional que utilizo cuando encaja con mis necesidades y el tipo de proyecto.",
    image: MongoIMG,
  },
  {
    name: "MySQL",
    description: "Utilizo MySQL para mis bases de datos relacionales.",
    image: MysqlIMG,
  },
];
export default SkillsData;
